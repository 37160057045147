.deprecation-warning {
    background-color: #fff3cd;
    border-top: 1px solid #ffeeba;
    color: #856404;
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
    text-align: center;
  
    p {
      margin: 0;
    }
  
    a {
      color: #856404;
      text-decoration: underline;
  
      &:hover {
        color: #533f03;
      }
    }
}