.footer {
    width: 100%;
    margin-top: auto;
    height: 220px;
    font-size: 12px;
    background: linear-gradient(
      230deg,
      #0090ff 0%,
      #39c1cb 99.99%,
      rgba(0, 144, 255, 0) 100%
    );
    padding: 3rem 3.5rem;
    display: flex;
    color: white;
  
    .logo {
      display: flex;
      height: 36px;
      width: 36px;
  
      span {
        font-size: 16px;
        font-weight: bold;
        margin-top: 0.5rem;
        margin-left: 0.5rem;
      }
    }
  
    .resources,
    .reach_out {
      margin-left: 16rem;
      margin-top: 0.5rem;
      color: white;
  
      a {
        text-decoration: none;
        color: inherit;
        font-size: 14px;
      }
  
      &-links {
        display: flex;
        flex-direction: column;
  
        a {
          margin-top: 1rem;
        }
      }
    }
  }