.App {
  

  h1 {
    padding-bottom: 20px;
  }

  h2 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: none;
    background-color: #fff;
    height: 4.4rem;
    font-family: "suisseintl";


    .avatarContainer {
      width: 40px;
      height: 40px;

      .avatarImage {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
  
    .arrowDownIcon {
      margin: 5px;
      display: flex;
      justify-content: center;
    }
 
    .headerLeftRight {
      display: flex;
      align-items: center;
    }
  
    .WalletMenu {
      border-radius: 20px;
      overflow: hidden;
      cursor: pointer;
      height: 40px;
      width: 40px;
    }
  
      
     
    .accountModal{
      height: 270px;
      overflow: scroll;
      width: 100%;
      padding: 0 10px;
    }

    .accountholder {
      cursor: pointer;
      position: relative;     
    }

    .networkselect {
      cursor: pointer;
      position: relative;
    }

    .datacap {
      padding-top: 19px;
      cursor: pointer;
    }

    .refresh {
      cursor: pointer;

      button {
        font-weight: bold;
        letter-spacing: 1px;
         background-color: #0090ff;
         color: white;
         padding : "4px";
         border-radius: "4px";
      }

      span {
        font-family: 'suisseintl';
      }
    }
    
    .headertitles {
      font-weight: bold;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    
    .accounttype {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      padding-bottom: 7px;
      line-height: 16px;
      letter-spacing: 0.4px;
    }

    .datacap {
      padding-left: 5px;
    }

    .datacapdata img {
      margin-right: 8px;
      float: right;
    }

    .copyaddress {
      padding-left: 5px;
      float: right;
      margin-right: 8px;
      margin-top: 6px;
    }

    a,
    a:focus,
    a:visited,
    a:link,
    a:hover,
    a:target {
      padding: 8px 20px;
      text-decoration: none;
      color: black;
      border: none;
      font-weight: 300;

      svg {
        padding-right: 10px;
      }
    }

    a.active {
      color: #003fe3;
    }
  }

  .walletpicker {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .networkselectholder {
    width: 250px;
    position: absolute;
    padding: 24px 16px;
    top: 55px;
    left: -10px;
    border: 1px solid #c7c7c7;
    border-radius: 8px;
    background-color: #fff;
    z-index: 1;

    .networkentry {
      padding-top: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .accountselectholder {
    width: 300px;
    position: absolute;
    padding: 5px 16px;
    top: 60px;
    left: -13px;
    border: 1px solid #c7c7c7;
    border-radius: 8px;
    background-color: #fff;
    z-index: 1;

    .viewswitch {
      padding: 10px;
    }

    .accountentry {
      padding-top: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: monospace;
      border-style: solid;
      border-width: 1px;
      border-radius: 4px;
      border-color: #c7c7c7;
      margin: 6px -10px;
    }

    .datacapdata {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }

    .accountdata {
      padding-left: 6px;
    }

    .importseedphrase {
      padding-top: 10px;
      font-weight: bold;
      top: calc(50% - 20px / 2);
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #0090ff;
    }
  }

 

  .main {
    max-width: 1280px;
    margin: auto;
    min-height: 100%;
    margin-top: 20px;
  

    .tabsholder {
      margin-bottom: 20px;
      padding-top: 50px;
      display: flex;
      justify-content: space-between;
      font-family: "suisseintl";

      .tabs {
        display: flex;

        > div {
          padding: 10px;
          margin-right: 10px;
          border-bottom: 1px solid #ccc;
          cursor: pointer;
        }

        > div.selected {
          border-bottom: 1px solid #0090ff;
          color: white;
        }
      }

      .tabssadd {
        button {
          font-family: "suisseintl";
          margin-left: 10px;
        }
      }
    }

    .nodata {
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #e5e5e5;
    }

    form {
      padding-bottom: 20px;

      .datacapholder {
        display: flex;
        max-width: 480px;

        .datacap {
          flex-grow: 1;
        }

        .datacapext {
          padding-top: 33px;
        }
      }
    }

    button {
      background-color: #0090ff;
    }

    button > div {
      height: 15px;
      width: 15px;
    }

    .alignright {
      width: 100%;
      text-align: right;

      .buttonsecondary {
        margin-top: 20px;
        background-color: #fff;
        color: #666;
        text-align: right;
      }
    }
  }
  .pagination {
    top: 890px;
    position: revert;
  }
}

.accountModal {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  text-align: "center";
  width: "300px";
  height: "300px";
  overflow: "scroll";
  padding: 10px;

  .accountsholder {
    padding: 20px 0px;

    .viewswitch {
      padding: 10px;
      margin: 10px;
      width: 20px;
    }

    .accountaddress {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 28px;
      color: #1a1a1a;
    }
  }

  .account {
    padding: 5px 0px;
    cursor: pointer;
  }

  input {
    margin: 20px 0px 10px 0px;
  }
}

.wizzardfirst {
  display: flex;

  .wizzardElement {
    flex-grow: 1;
    align-items: center;
    text-align: center;
    border: 1px solid #ccc;
    margin: 10px;
    cursor: pointer;
    padding: 10px;

    .number {
      font-size: 24px;
      padding-bottom: 15px;
      padding-top: 10px;
    }

    .title {
      padding-bottom: 15px;
    }

    .description {
      color: #666;
    }
  }
}

.requestmodal {
  height: 720px;
}

.addmodal,
.confirmmodal {
  .inputholder {
    padding-top: 20px;
  }

  input {
    font-feature-settings: normal !important;
  }

  .buttondiv {
    padding-top: 18px;
  }

  .holdermessage {
    padding-top: 10px !important;
  }

  .errorAddress {
    font-feature-settings: normal !important;
    color: red;
    padding-top: 6px;
  }

  .css-1pck2x {
    margin-bottom: 1px !important;
    font-family: "suisseintl";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }

  .datacapholder {
    padding-top: 20px;
  }

  .title {
    font-size: 22px;
    padding-top: 25px;
    padding-left: 40px;
    font-weight: bold;
  }

  .twopanel {
    display: flex;
    width: 900px;
    padding-left: 30px;
    padding-right: 30px;

    > div {
      flex-basis: 50%;
      padding-left: 10px;
      padding-right: 10px;
    }

    .css-1ev2p8p {
      font-family: Suisse Int;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0px;
      line-height: 16px;
    }
  }

  .ledgermessage {
    max-width: 250px;
    text-align: center;
    padding-top: 12px;

    p {
      padding-top: 7px;
    }
  }

  .centerbutton {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 20px;

    button {
      background-color: #0090ff;
      width: 176px;
    }

    #sendbutton {
      margin-top: -40px;

      button {
        font-family: "suisseintl";
      }
    }
  }

  .methodselection {
    margin-top: 15px;

    .methodlabel {
      margin-bottom: 8px;
      font-family: "suisseintl";
    }

    .methodtype {
      font-family: "suisseintl";
    }

    label {
      margin-bottom: 4px;
    }
  }

  .loginwarn {
    margin-top: 0px;
    position: absolute;
    height: 40px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .makerequest {
    height: 560px;
    width: 570px;
    padding-top: 20px;

    .inputholder,
    .datacapholder {
      padding-left: 35px;
    }

    .methodselection {
      text-align: center;
    }

    .regionlabel {
      margin-bottom: 1px !important;
      font-family: "suisseintl";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
    }
  }

  .docsmessage {
    padding-top: 18px;
    max-width: 460px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
  }
}

.githubrequest {
  margin-top: 0px !important;
}

#githublogin {
  height: 40px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  min-height: 300px;

  button {
    height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    outline: 0;
    border: 0;
    min-height: 40px;
    padding: 6px 24px 6px 24px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 16px;
    -webkit-letter-spacing: 0.2px;
    -moz-letter-spacing: 0.2px;
    -ms-letter-spacing: 0.2px;
    letter-spacing: 0.2px;
    font-family: "inter-semi-bold", -apple-system, BlinkMacSystemFont, arial,
      sans-serif;
    -webkit-transition: 200ms ease all;
    transition: 200ms ease all;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    background-color: #0090ff;
    color: #ffffff;
  }
}

.main table {
  margin-top: 20px;
  font-size: 12px;
  border-collapse: collapse;
  width: 100%;
  font-family: "suisseintl";

  thead {
    font-weight: bold;

    tr {
      border-bottom: 1px solid #000;
    }
  }

  tr {
    border-bottom: 1px solid #e5e5e5;
    cursor: pointer;

    td {
      padding: 12px;
      svg {
        padding-left: 5px;
      }
    }
  }

  tr.selected {
    background-color: #e5e5e5;
  }
}

.container {   
  .tabsholder {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    height: 600px;
    left: 0;
    right: 0;
    background: #fff;
    top: 220px;
    text-align: center;
    display: flex;
    z-index: 1;
    height: 60px;
    border-top: 1px solid #0091ff;
    border-left: 1px solid #0091ff;
    border-right: 1px solid #0091ff;
    cursor: pointer;

    .tab {
      flex: 50%;
      margin-top: 20px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      border-bottom: 2px solid #f1f1f1;
    }

    .selected {
      border-bottom: 1px solid #0091ff;
    }
  }

  .twooptions {
    .option {
      position: relative;
      border-right: 1px solid #0091ff;
      border-bottom: 1px solid #0091ff;
    }
  }

  .options {
    transform: translateY(-30px);
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    height: 600px;
    left: 0;
    right: 0;
    background: #fff;
    text-align: center;
    display: flex;
    border-right: 1px solid #0091ff;
    border-left: 1px solid #0091ff;

    

    .columnleft {
      border-top: 1px solid #0091ff;
      flex: 50%;

      img {
        margin-top: 200px;
      }
    }

    .columright {
      border-top: 1px solid #0091ff;

      .optiontitle {
        margin-top: 220px;
        text-align: justify;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
      }

      .optiondesc {
        text-align: justify;
        margin-right: 150px;
        margin-top: 25px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .started {
    position: absolute;
    top: 740px;
    max-width: 1280px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 10px;
    display: flex;

    .siglebutton button {
      height: 20px;
      width: 180px;
      left: 0px;
      right: 0px;
      top: calc(50% - 20px / 2);
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      background: #0091ff;
    }

    .buttonsholder {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      display: flex;
      padding-left: 180px;

      .buttonlegend {
        text-align: center;
        flex: 50%;
        width: 320px;
        text-align: left;

        .info:nth-child(1) {
          font-style: italic;
        }
      }
    }
  }

  .form {
    min-height: 100%;
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }
}
.viewFourContainer {
  background-image: url("/img/bg_image.png");
  display: flex;
  align-items: center;
  flex-direction: column;
  

  .welcome4 {
    font-size: xx-large;
    padding-top: 5%;
    width: 60%;
  }

  .content {
    padding: 5% 0 10% 0;
    width: 100%;

    .otherInfoContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .otherInfoHeader {
        text-align: center;
        font-variant-caps: all-small-caps;
        padding-right: 5%;
      }

      .otherInfoLabel {
        font-size: large;
        padding: 1%;
      }

      .otherInfoDiv {
        display: flex;
        flex-direction: column;
        width: 60%;
        margin: 1%;

        textarea {
          height: 70px;
        }
      }
    }
    .otherInfoButtonContainer {
      display: flex;
      justify-content: flex-end;
      margin-top: 5%;
    }

    .select-slot {
      display: flex;
    }
  }
}
.formContainer {
  background-image: url("/img/bg_image.png");
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  min-height: 100%;

  .welcome0 {
    display: flex;
    flex-direction: column;
    font-size: xx-large;
  }
  .welcome1 {
    display: flex;
    flex-direction: column;
    font-size: xx-large;
    max-width: 35%;
  }
  .welcome2 {
    display: flex;
    flex-direction: column;
    font-size: xx-large;
    max-width: 30%;
  }
  .welcome3 {
    font-size: xx-large;
    display: flex;
    flex-direction: column;
    font-size: xx-large;
    max-width: 30%;
  }

  .content {
    padding: 10% 0 10% 0;
    width: 50%;

    .titleIssueBox {
      font-size: large;
    }

    .issueBoxContainer {
      overflow: auto;
      max-height: 350px;

      .issueBox {
        width: 400px;
        height: 100px;
        padding: 10px;
        margin: 10px;
        cursor: pointer;
        border: 1px solid #0091ff;
        border-radius: 5px;
        margin-left: 0;

         p {
           margin-top: 14px;
           margin-left: 10px;
         }
      }
    }

    .inputholder {
      display: flex;
      align-items: flex-end;

      .dropdown-input {
        color: blue;
      }
    }

    .select-slot {
      display: flex;
    }
  }
}

@media only screen and (max-width: 850px) {
  .buttonsholder {
    display: block !important;
  }

  .buttonlegend:nth-child(2) {
    padding-top: 20px;
  }

  .buttonsholder {
    padding-left: 120px !important;
  }
}

.doublebutton button,
.doublebutton button:visited {
  margin-right: 80px;
  height: 20px;
  left: 0px;
  right: 0px;
  top: calc(50% - 20px / 2);
  font-family: "suisseintl";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width: 180px;
  background: #0091ff;
}

.doublebutton button:nth-child(2) {
  background: #fff !important;
  color: #0091ff;
}

.buttonsverifiers {
  display: block !important;
}

.verifierview {
  border-bottom: 1px solid #0091ff;
}

.verifiers {
  .tableverifiers {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    left: 0;
    right: 0;
    background: #fff;
    text-align: center;
    display: flex;
    box-sizing: border-box;

    .minersTableLoadSpinner {
      display: flex;
      justify-content: center;
      align-items: top;
    }

    @keyframes tableAppearSmooth {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    table {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      left: 0;
      right: 0;
      border-collapse: collapse;
      opacity: 1;
      -webkit-transition: opacity 2s;
      transition: opacity 2s;
      animation: 1.5s tableAppearSmooth;
      text-align: left;

      tr:hover {
        background-color: #34bad1;
        transition: background-color 0.3s;
      }
    }

    thead {
      font-weight: bold;

      tr {
        border-bottom: 1px solid #000;
      }
    }

    tr {
      border-bottom: 1px solid #e5e5e5;
      cursor: pointer;

      td {
        padding: 12px;
        font-family: "suisseintl";
        svg {
          padding-left: 5px;
        }
      }
    }

    .data {
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #1a1a1a;
      display: block;
      flex: 95%;
    }

    .nodata {
      padding-top: 100px;
      width: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    .checks {
      flex: 5%;
      margin-top: 50px;

      figure {
        margin-left: 20px;
        margin-bottom: 30px;
      }
    }
  }

  .miners {
    top: 90px !important;
    text-align: left;

    a {
      color: #000;
    }

    svg {
      padding-left: 3px;
    }
  }
}

.preonboarding {
  .started {
    .doublebutton button {
      width: 320px;
      background: #ffffff;
      color: #1a1a1a;
      border: 1px solid #c7c7c7;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
}

.wrapperverifiers {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  min-height: 825px;
  left: 0;
  right: 0;
  transform: translateY(-30px);
  background: #fff;
  border: 1px solid #0091ff;
  box-sizing: border-box;
  margin-bottom: 30px;
}


.errormodal,
.confirmmodal {
  width: 570px;
  height: 520px;

  .title {
    text-align: center;
    font-family: "suisseintl";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
  }

  .description {
    text-align: center;
    font-family: "suisseintl";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    max-width: 350px;
    margin: auto;
  }

  .warn {
    text-align: center;
    font-family: "suisseintl";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    max-width: 5000px;
    margin: auto;
  }

  .img {
    max-width: 180px;
    max-height: 214px;
    margin: auto;
    margin-top: 30px;
  }

  .button {
    width: 176px;
    margin: auto;
    margin-top: 40px;

    button {
      width: 176px;
      background: #33a7ff;
      border-radius: 4px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
    }
  }

  form {
    height: 32px;
    width: 384px;
    left: 0px;
    top: 0px;
    border-radius: 4px;
    margin: auto;

    .inputholder {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #1a1a1a;
    }
  }
}

.buttonnotfilled {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  button,
  button:checked,
  button:active,
  button:hover,
  button:visited,
  button:checked {
    width: 176px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    color: #33a7ff;
    border: 1px solid #33a7ff;
  }
}

.tableselects {
  transform: translateY(30px);
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  left: 0;
  right: 0;
  background: #ffffff;

  .tabletitle {
    font-family: "suisseintl";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0px 12.5px 0px 12.5px;
    max-width: 1280px;
    display: flex;

    .title {
      float: left;
      width: 50%;
    }
    .formname {
      float: right;
      top: -6px;
    }
  }
}

.warnmodalledger {
  height: 220px;
  width: 650px;
  display: flex;
  text-align: center;
  font-family: "suisseintl";
  justify-content: center;
    align-items: center;
    flex-direction: column;

  .message {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    margin: auto;
  }

  p {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .title {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .list {
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    margin: auto;
  }

  table {
    top: 65px;
    margin: auto;
    font-family: "suisseintl";
    width: 98%;
    table-layout: fixed;
    border-collapse: collapse;
  }

  table {
    thead {
      font-weight: 700;

      tr {
        border-bottom: 1px solid #000;
      }
    }

    tr {
      border-bottom: 1px solid #e5e5e5;
    }

    td {
      width: 50%;
      padding-bottom: 8px;
      padding-top: 5px;
      word-wrap: break-word
    }
  }

  .ledgermessage {
   display: flex;
   justify-content: center;
   flex-direction: column;
  }

  button {
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #0090ff;
    font-family: "suisseintl";
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }
}


.notaryinfo {
  float: left;
  margin-right: 10px;
}

.loginmodal {
  height: 583px;
  width: 642px;
  left: 1405px;
  top: -136px;
  border-radius: 0px;

  .imgheader img {
    position: absolute;
    width: 194px;
    height: 180px;
    left: 224px;
    top: 51px;
  }

  .info {
    position: absolute;
    width: 40px;
    height: 333px;
    left: 1px;
    top: 153px;
    background: #ffffff;

    .title {
      position: absolute;
      width: 440px;
      height: 36px;
      left: 101px;
      top: 150px;
      font-size: 24px;
      line-height: 36px;
      align-items: center;
      text-align: center;
      color: #1a1a1a;
      font-family: "suisseintl";
    }

    .description {
      position: absolute;
      width: 440px;
      height: 72px;
      left: 101px;
      top: 194px;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      text-align: center;
      color: #1a1a1a;
      font-family: "suisseintl";
    }
  }

  .tabs {
    position: absolute;
    height: 25px;
    left: 1px;
    top: 440px;
    background: #ffffff;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-evenly;
    color: #33a7ff;

    .tab {
      cursor: pointer;
    }
  }

  .multisiginput {
    width: 200px;
    margin-top: 5px;
    padding: 5px;
  }

  .buttons {
    position: absolute;
    height: 96px;
    left: 1px;
    top: 486px;
    background: #ffffff;
    width: 100%;

    button {
      bottom: 0px;
      border: 1px solid #595959;
      box-sizing: border-box;
      border-radius: 4px;
      background: #ffffff;
      font-family: "suisseintl";
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: #1a1a1a;

      img {
        padding-right: 10px;
      }
    }

    .left,
    .right {
      display: inline-block;
      text-align: center;

      img {
        max-width: 20px;
        max-height: 20px;
        margin-right: 10px;
      }
    }

    .left {
      width: 50%;
      float: left;
    }

    .right {
      width: 50%;
      float: right;
    }

    .center {
      max-width: 220px;
      left: 0;
      right: 0;
      margin: auto;

      p {
        margin-left: -40px;
        margin-right: -50px;
        margin-top: 5px;
      }
    }
  }
}

.buttonverify {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}

.minerslogin {
  margin-top: 180px;
}

.twooptionsbelow {
  top: 820px !important;
}

.paginationminers {
  top: 630px !important;
}
.ownedrow {
  background-color: #ccc;
}
.verificationoptions {
  // width: 570px;
  // height: 380px;
  font-family: "suisseintl";
  padding: 10px;

  .buttoncard {
    height: 150px;
    margin-top: 15px;
    border-radius: 10px;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    border: 1px solid #1a1a1a; 
  }

  .buttoncard:nth-child(3) {
    padding-top: 10px;
  }

  .methods {
    margin-bottom: 20px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: center;
  }

  .title {
    position: relative;
    padding-top: 15px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: center;
  }

  .subtitle {
    padding-top: 5px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;
  }

  .description {
    padding-top: 10px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
    padding-left: 27px;
    padding-bottom: 15px;
  }
}

.wrapperclients .tableverifiers {
  border-top: 1px solid #0091ff !important;
  border-left: 1px solid #0091ff !important;
  border-right: 1px solid #0091ff !important;
  height: 620px !important;

  position: absolute !important;
  top: 5px !important;
  z-index: 2;
}

.wrapperclients .pagination {
  top: 625px !important;
  z-index: 1;
  background-color: #fff;
}

.wrapperclients {
  background: linear-gradient(360deg, #006fec 0%, #39c1cb 100%);
  max-width: 100% !important;
  height: 1200px;

  .tableselects {
    z-index: -2;
    border-top: 1px solid #0091ff !important;
  }
  .tableverifiers {
    top: 50px;
    height: 650px;
  }
}

* {
  font-feature-settings: normal !important;
}

.header {
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 50px;
  top: 0;
  background-color: white;
}

.iconExtLink {
  color: lightseagreen;
}
.iconExtLink:hover {
  color: #0091ff;
}

.selected {
  color: white;
  background-color: #0091ff;
  border-radius: 4px;
  padding: 3px;
  margin-bottom: 4px;
  box-shadow: 2px 2px grey;
  transition: 0.1s;
}

.searchMakeReuestForm {
  display: flex;
  flex-direction: row;
  margin-left: 10%;
  button {
    width: 30%;
    margin-right: 10px;
  }
}

.disabledRow {
  color: rgb(97, 96, 96);
  opacity: 0.9;
}

div[role="columnheader"] div {
  font-size: 14px;
  font-weight: bold;
}

.rdt_TableHeader {
  padding: 30px 0;
}

//this css styles is used for /logsroute to make them look like form/button/textfield Group
.inputRounded-1 div{
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
}

.inputRounded-1 .MuiFilledInput-underline:before {
  border-bottom: 0;
}

.inputRounded-2 .MuiFilledInput-underline:before {
  border-bottom: 0;
}

.inputRounded-2 div{
  border-radius: 0;
}


//This styles added for table , to fix overflow problem happening on the large request table...

.rdt_Pagination div button {
  background-color: inherit !important;
}

.large-request-table div div {
  display: flex !important
}

.gotoTopLogs {
  background-color: white;
  color: #0090ff;
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0090ff;
    color: white;
  }

.rotation {
	animation: rotation 2s infinite linear;
}

@keyframes rotation {
		from {
			transform: rotate(0deg);
		}
		to {
				transform: rotate(359deg);
		}

 }
}
